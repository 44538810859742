import React from 'react';
import PropTypes from 'prop-types';

import './Loader.scss';

function Loader({ className }) {
  return <div className={`page-loader ${className}`}></div>;
}

Loader.propTypes = {
  className: PropTypes.string,
};

export default Loader;
