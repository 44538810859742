import React from 'react';
import ReactDOM from 'react-dom';

// Necessary to use modern ES6 features like generators (see redux-saga)
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import App from './views/App';
import * as serviceWorker from './serviceWorker';

// Import the style of the application root, including arduino-sass
import './styles/index.scss';

// Mounts the App component into the <div id="root" /> element in the index.html
ReactDOM.render(
  React.createElement(App, null, null),
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
