import React from 'react';
import PropTypes from 'prop-types';

import './icons.svg';

const Icon = props => (
  <svg className={`${props.className || ''} icon`}>
    <use xlinkHref={`#icons_${props.name}`} />
  </svg>
);

Icon.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

export default Icon;
