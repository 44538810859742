import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import { setCookie, getCookie } from '../../utils/cookies';

const langs = [{ value: 'en', title: 'English' }];

const defaultSettings = {
  locale: langs[0],
};

const getDefaultState = () => {
  const key = getCookie('lang');
  if (!key) {
    return defaultSettings;
  }
  const locale = langs.find(item => item.value === key);
  if (!locale) {
    return defaultSettings;
  }

  return {
    locale,
  };
};

const defaultState = getDefaultState();

const LayoutContext = createContext(defaultState);

const LayoutProvider = props => {
  const [value, setValue] = useState(defaultState);

  const setLocale = locale => {
    setValue({
      locale: locale,
    });
    setCookie('lang', locale.value);
  };

  return (
    <LayoutContext.Provider value={{ ...value, setLocale, langs }} {...props}>
      {props.children}
    </LayoutContext.Provider>
  );
};

LayoutProvider.propTypes = {
  children: PropTypes.node,
};

export { langs, LayoutContext, LayoutProvider };
