import React from 'react';
import { LayoutContext } from '../views/App/layout-context';
import Loader from '../views/ui-common/Loader/Loader';
import { useQuery } from 'react-apollo';

const QueryWrapper = (
  query,
  Component,
  props,
  variables = {},
  options = {},
) => {
  const { locale } = React.useContext(LayoutContext);

  const { data, loading } = useQuery(query, {
    variables: { locale: locale.value, ...variables },
    ...options,
  });

  if (loading) {
    return <Loader />;
  }

  if (!data) {
    return <React.Fragment />;
  }

  return <Component {...props} data={data} />;
};

export default QueryWrapper;
